.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    left: 0;
    margin-left: 120px;
    background: #fdfdfd;
    padding-left: 15px;
    padding-right: 15px;
    transition: all 0.25s ease;
}
.footer p {
    display: inline-block;
    font-size: 12px;
    line-height: 40px;
}