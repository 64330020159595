.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}
.skeleton-text-2 {
  width: 100%;
  height: 0.7rem;
  margin: 10px;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 35%;
  padding: 20px 0px;
}

.skeleton-footer {
  width: 20%;
  float: right;
  padding: 20px 0px;
}
